* {
  margin: 0;
}

.keep-scrolling {
  background-color: #fff;
  width: 300px;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
}

.keep-scrolling::-webkit-scrollbar {
  display: none;
}

.keep-scrolling {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
